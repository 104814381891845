import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import { DFVoucherListRequest, DFVoucherListResponse } from 'models/digital-food-voucher/digital-food-voucher-list';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DigitalFoodVoucherService {
  constructor(private http: HttpClient) {}

  checkDFVModuleAccess(): Observable<boolean> {
    const accessToken = localStorage?.getItem('user.accessToken');

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
      'Skip-Interceptor': 'true'
    });

    const requestBody: DFVoucherListRequest = {
      filter: {
        voucher_status: []
      },
      pagination: {
        page: 1,
        page_size: 1
      }
    };

    const apiPath = `${environment.microserviceApiGatewayEndpoint}/${environment.microserviceApiPrefix}/dfvoucher/api/v1/public/digitalfoodvoucher/list`
    return this.http.post<DFVoucherListResponse>(apiPath, requestBody, { headers, withCredentials: false })
      .pipe(
        map(response => response?.data?.length > 0)
      );
  }
}
